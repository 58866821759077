<template>
  <div class="allowed--email-field mb-2">
    <span class="mr-2">{{ index + 1 }}. </span>
    <v-text-field
      id="emailField"
      class="mr-2"
      :value="allowedEmail"
      @input="inputEmail"
      type="text"
      name="allowedEmails[]"
      outlined
      hide-details
    />
    <v-btn color="error" @click="removeAllowedEmail">
      <v-icon v-text="'mdi-trash-can-outline'" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'allowed-email-field',
  props: ['allowedEmail', 'index', 'allowedEmailLength'],
  methods: {
    inputEmail(input) {
      this.$store.commit('promo/form/INPUT_EMAIL', {
        email: input,
        index: this.index,
      });
    },
    removeAllowedEmail() {
      this.$store.commit('promo/form/REMOVE_ALLOWED_EMAILS', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.allowed--email-field {
  display: flex;
  align-items: center;
}
</style>
